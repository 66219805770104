"use client";

import { useRouter, useSearchParams } from "next/navigation";
import SafeArea from "@/components/safe-area";
import { ConvertCountry } from "@/data/regions";
import { decodeUrl } from "@/lib/window";
import { FindPlanByName, GetBundlePlans } from "@/data/plans";
import { Plan } from "@/data/data";
import { DisplayPlans } from "@/components/display-plans";

export default function Content() {
  const router = useRouter();
  const searchParams = useSearchParams();
  const country = ConvertCountry(searchParams?.get("c") || "US");
  const hashedPlan = decodeUrl(searchParams?.get("p") || "") || "";
  const planName = atob(hashedPlan);
  const currentPlan = FindPlanByName(planName);
  const group =
    (planName.length > 0
      ? currentPlan?.group
      : searchParams?.get("tp") || null) || "saas";

  const plans: Plan[] = GetBundlePlans(group, country);

  const handleSelectedPlan = async (plan: Plan) => {
    const hashedPlan = btoa(plan.publicName);
    router.push(`/search/?c=${plan.country}&p=${hashedPlan}`)
  }

  return (
    <>
      <main className="container mx-auto max-w-4xl mb-12" style={{ flex: "1" }}>
        <SafeArea>
          {
            group == "lifetime" ? 
            <DisplayPlans
            title={country == "CA" ? "Offer: Lifetime Deal Business Phone Number 🇨🇦" : `Offer: Business phone number, 3 users included`}
            description={country == "CA" ? "To redeem offer, do not navigate away from this screen." : "To redeem offer, do not navigate away from this screen."}
            plans={plans}
            tabs={{
              tabs: [
                { title: "Basic", value: "basic" },
                { title: "Pro+", value: "pro" },
              ],
            }}
            defaultPlan="basic"
            handleSelectedPlan={handleSelectedPlan}
          /> : 
          <DisplayPlans
            title={country == "CA" ? "Order Virtual Phone Number For Your Canada Business 🇨🇦" : `Offer: Business phone number, 4 users included`}
            description={country == "CA" ? "" : ""}
            plans={plans}
            tabs={{
              tabs: group.startsWith("promo")  ? [] : [
                { title: "Monthly", value: "month" },
                { title: "Annually", value: "year" },
              ],
            }}
            defaultPlan={ group.startsWith("promo") ? undefined :  "month"}
            handleSelectedPlan={handleSelectedPlan}
          />
          }
        </SafeArea>
      </main>
      {/* <ReviewView /> */}
    </>
  );
}
